import './languageSelect.css';

const LanguageSelect = ( props )=>{
    return(
        <section className="LanguageSelect">
            <h2>No language selected</h2>
            <p>Please pick a desired language</p>
            <div>
                <button onClick={ props.onButtonClick } data-lang="NO"></button>
                <button onClick={ props.onButtonClick } data-lang="EN"></button>
            </div>
        </section>
    )
}

export default LanguageSelect;