import './knowledgeRow.css';

const KnowledgeRow = ( props )=>{
    return(
        <div className="knowledge_row">
            <img src={ props.img1 } alt=""/>
            <img src={ props.img2 } alt=""/>
            <img src={ props.img3 } alt=""/>
        </div>
    )
}

export default KnowledgeRow;