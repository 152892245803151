import { useState, useEffect } from 'react';
import LanguageSelect from './Components/languageSelect/languageSelect.js';
import Sidebar from './Components/sidebar/sidebar.js';
import Home from './Pages/home/home.js';
import Knowledge from './Pages/knowledge/knowledge.js';
import Projects from './Pages/projects/projects.js';
import Settings from './Pages/settings/settings.js';
import ErrorPage from './Pages/404/errror.js';
import './App.css';
import EN from './languages/english.js';
import NO from './languages/norwegian.js';

function App() {
  const [ display, setDisplay ] = useState('Home');
  const [ language, setLanguage ] = useState('');
  const [ colorMode, setColorMode ] = useState('dark');

  useEffect(()=>{
    if( localStorage.getItem('Language') === 'NO' ){
      setLanguage( NO );
    } else if( localStorage.getItem('Language') === 'EN' ){
      setLanguage( EN );
    } 
  },[]);

  const displayPage = ()=>{
    switch( display ){
      case 'Home':
        return <Home lang={ language }/>
      case 'Knowledge':
        return <Knowledge lang={ language }/>
      case 'Projects':
        return <Projects lang={ language }/>
      case 'Settings':
        return <Settings lang={ language } changeLang={ changeLanguage } changeColor={ changeColor }/>
      default:
        return <ErrorPage lang={ language }/>
    }
  }

  const onNavItemClick = ( e )=>{
    let target;
    if( e.target.className === 'NavItem' || e.target.className === 'NavItem active'){
      setDisplay( e.target.id );
      target = e.target;
    } else if( e.target.className === 'NavItem_text' || e.target.className === 'NavItem_icon' ){
      setDisplay( e.target.parentElement.id );
      target = e.target.parentElement;
    } else{
      setDisplay( '404' );
    }
    toggleActive( target );
  }

  const changeLanguage = ( event )=>{
    if( event.target.attributes[0].value === 'NO'){
      setLanguage( NO );
    } else if( event.target.attributes[0].value === 'EN' ){
      setLanguage( EN );
    } else{
      console.error( 'Failed setting language' );
    }
    localStorage.setItem('Language', event.target.attributes[0].value);
    toggleActive( document.querySelector('#Home') );
    setDisplay('Home');
  }

  const changeColor = ( event )=>{
    if( colorMode !== event.target.attributes[0].value ){
      const colorValue = event.target.attributes[0].value;
      setColorMode( colorValue )
      const root = document.querySelector(':root');
      colorValue === 'dark' ? root.style.setProperty('--backgroundColor', '#1c1d2c') : root.style.setProperty('--backgroundColor', '#e9e9e9');
      colorValue === 'dark' ? root.style.setProperty('--backgroundImgColor', 'rgba(0,0,0,0.65)') : root.style.setProperty('--backgroundImgColor', 'rgba(255,255,255,0.65)');
      colorValue === 'dark' ? root.style.setProperty('--mainTextColor', 'rgb(240, 240, 240)') : root.style.setProperty('--mainTextColor', 'rgb(24, 24, 24)');
      colorValue === 'dark' ? root.style.setProperty('--menuColor', 'rgba(17, 17, 17, 0.8)') : root.style.setProperty('--menuColor', 'white');
      colorValue === 'dark' ? root.style.setProperty('--menuTextColor', 'white') : root.style.setProperty('--menuTextColor', 'black');
    }
  }

  const toggleActive = ( target )=>{
    const navElements = document.querySelectorAll('.NavItem');
    for( let i = 0; i < navElements.length; i++ ){
      if( navElements[i].classList[1] ){
        navElements[i].classList.remove('active');
      }
    }
    target.className += ' active';
  }

  return (
    <div className="App">
      <Sidebar onNavClick={ onNavItemClick } lang={ language } color={ colorMode }/>
      <section className='App_container'>
        { displayPage() }
      </section>
      { language === '' ? <LanguageSelect onButtonClick={ changeLanguage } /> : '' }
    </div>
  );
}

export default App;
