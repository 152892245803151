import './settings.css';

const Settings = ( props )=>{
    return(
        <div className="Page" id="Settings_Page">
            <h1>{ props.lang.settings_title }</h1>
            <div>
                <button onClick={ props.changeColor } data-color="light">{ props.lang.lightBtn }</button>
                <button onClick={ props.changeColor } data-color="dark">{ props.lang.darkBtn }</button>
            </div>
            <div className='languageButtons'>
                <button onClick={ props.changeLang } data-lang="NO"></button>
                <button onClick={ props.changeLang } data-lang="EN"></button>
            </div>
        </div>
    )
}

export default Settings;