import './projectElement.css';

const ProjectElement = ( props )=>{
    return(
        <div className="ProjectElement" id={ props.id } onClick={ props.click } data-info={ props.data }>
            <img src={ props.img } alt={ props.id } />
            <h2>{ props.data[0] }</h2>
        </div>
    )
}

export default ProjectElement;