// Icons
export const html_icon = require('./knowledge_icons/html.webp');
export const css_icon = require('./knowledge_icons/css.webp');
export const js_icon = require('./knowledge_icons/js.webp');
export const angular_icon = require('./knowledge_icons/angular.webp');
export const react_icon = require('./knowledge_icons/react.webp');
export const vue_icon = require('./knowledge_icons/vue.webp');
export const sass_icon = require('./knowledge_icons/sass.webp');
export const ts_icon = require('./knowledge_icons/ts.webp');
export const git_icon = require('./knowledge_icons/git.webp');
export const sql_icon = require('./knowledge_icons/sql.webp');
export const node_icon = require('./knowledge_icons/nodejs.webp');
export const jquery_icon = require('./knowledge_icons/jquery.webp');

// Project Images
export const gameofboards = require('./projects/gameofboards.webp');
export const spacefeed = require('./projects/spacefeed.webp');
export const musicplayer =  require('./projects/musicplayer.webp');
export const qatarmarketing = require('./projects/qatarmarketing.webp');
export const lindex = require('./projects/lindex.webp');
export const vinoteket = require('./projects/vinoteket.webp');
export const uno = require('./projects/uno_clone.webp');
export const win7 = require('./projects/win7.webp');
export const tripdotcom = require('./projects/trip.com.webp');
export const stonehearth = require('./projects/stonehearth.webp');
export const bidroom = require('./projects/bidroom.webp');

// Sidebar icons
export const about_white_icon = require('./about_white.png');
export const about_black_icon = require('./about_black.png');
export const knowledge_white_icon = require('./knowledge_white.png');
export const knowledge_black_icon = require('./knowledge_black.png');
export const projects_white_icon = require('./projects_white.png');
export const projects_black_icon = require('./projects_black.png');
export const settings_white_icon = require('./settings_white.png');
export const settings_black_icon = require('./settings_black.png');
