import './navItem.css';

const NavItem = ( props )=>{
    return(
        <div className={ props.class } id={ props.id } onClick={ props.handleClick }>
            <img className="NavItem_icon" src={ props.img } alt={ props.text }/>
            <p className='NavItem_text'>{ props.text }</p>
        </div>
    )
}

export default NavItem;