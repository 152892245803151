import { useState } from 'react';
import ProjectElement from '../../Components/projectElement/projectElement';
import ProjectView from '../../Components/projectView/projectView';
import './projects.css';

const Projects = ( props )=>{
    const [ displayProject, setDisplayProject ] = useState(false);
    const [ projectData, setProjectData ] = useState([]);

    const clickHandle = ( event ) => {
        const data = event.target.parentElement.dataset['info'].split(',');
        setDisplayProject( displayProject => ( displayProject = !displayProject ) );
        if( projectData.length < 1 ){
            setProjectData( ...projectData, data );
        } else{
            setProjectData( data );
        }
    }

    const closeProjectView = ()=>{
        setDisplayProject( displayProject => ( displayProject = !displayProject ) );
        setProjectData([]);
    }

    return(
        <section className="Page" id="Projects_Page">
            <h1>{ props.lang.projects_title }</h1>
            <div className='projects_container'>
                <ProjectElement id={ props.lang.Project1[0] } img={ props.lang.Project1[1] } data={ props.lang.Project1 } click={ clickHandle }/>
                <ProjectElement id={ props.lang.Project2[0] } img={ props.lang.Project2[1] } data={ props.lang.Project2 } click={ clickHandle }/>
                <ProjectElement id={ props.lang.Project3[0] } img={ props.lang.Project3[1] } data={ props.lang.Project3 } click={ clickHandle }/>
                <ProjectElement id={ props.lang.Project4[0] } img={ props.lang.Project4[1] } data={ props.lang.Project4 } click={ clickHandle }/>
                <ProjectElement id={ props.lang.Project5[0] } img={ props.lang.Project5[1] } data={ props.lang.Project5 } click={ clickHandle }/>
                <ProjectElement id={ props.lang.Project6[0] } img={ props.lang.Project6[1] } data={ props.lang.Project6 } click={ clickHandle }/>
                <ProjectElement id={ props.lang.Project7[0] } img={ props.lang.Project7[1] } data={ props.lang.Project7 } click={ clickHandle }/>
                <ProjectElement id={ props.lang.Project8[0] } img={ props.lang.Project8[1] } data={ props.lang.Project8 } click={ clickHandle }/>
                <ProjectElement id={ props.lang.Project9[0] } img={ props.lang.Project9[1] } data={ props.lang.Project9 } click={ clickHandle }/>
                <ProjectElement id={ props.lang.Project10[0] } img={ props.lang.Project10[1] } data={ props.lang.Project10 } click={ clickHandle }/>
                <ProjectElement id={ props.lang.Project11[0] } img={ props.lang.Project11[1] } data={ props.lang.Project11 } click={ clickHandle }/>
            </div>
            { displayProject ? 
                <ProjectView 
                    title={ projectData[0] } 
                    img={ projectData[1] }
                    text={ projectData[2] }
                    link={ projectData[3] }
                    linkText={ props.lang.Project_link }
                    closeWindow={ closeProjectView } /> 
                : '' 
            }
        </section>
    )
}

export default Projects;