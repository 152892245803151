import { gameofboards, lindex, musicplayer, 
    qatarmarketing, spacefeed, uno, vinoteket, 
    win7, tripdotcom, stonehearth, bidroom } 
from '../Resources/imageIndex';

const Norwegian = ()=>{
    const language = {
        // Sidebar
        sidebar_home: 'Hjem',
        sidebar_knowledge: 'Kunnskap',
        sidebar_projects: 'Prosjekter',
        sidebar_settings: 'Innstillinger',
        // Home
        home_title: 'Velkommen',
        home_text: 'Mitt navn er Jonas,',
        home_text2: 'En teknologi entusiast og front-end utvikler bosatt i Oslo, Norge. ',
        home_text3: 'Datanerd med høy interesse for det meste som omhandler tech, Dataspill Extraordinaire',
        home_text4: 'og superbruker av både Windows og Linux.',
        // Knowledge
        knowledge_title: 'Kunnskap',
        knowledge_text1: 'Kjennskap til',
        knowledge_text2: 'Begrenset kjennskap til',
        // Projects
        projects_title: 'Prosjekter',
        // Settings
        settings_title: 'Innstillinger',
        lightBtn: 'Lys modus',
        darkBtn: 'Mørk modus',
        // Project elements
        Project_link: 'Åpne prosjekt',
        Project1: [
            'Game of Boards',
            gameofboards,
            'Skoleprosjekt - Lag et nettleser brettspill basert på den populære tv serien Game of Thrones. Bug - refresh siden når du velger karakter første gang',
            'https://jonasthomassen.no/projects/gameofboards/'
        ],
        Project2: [
            'SpaceFeed',
            spacefeed,
            'Skoleprosjekt - Design av nettside for fiktivt firma SpaceFeed. Nyhetsside for folk med interesse for verdensrommet. Gammel data fra API',
            'https://jonasthomassen.no/projects/spacefeed/'
        ],
        Project3: [
            'Musikkspiller',
            musicplayer,
            'Hobby - Enkel musikkspiller. Spiller per nå tilfeldig av 4 sanger hentet fra en array',
            'https://jonasthomassen.no/projects/musicplayer/'
        ],
        Project4: [
            'Qatar Airways Kampanje',
            qatarmarketing,
            'Marketsføring / Nyhetsbrev kampanje for Qatar Airways',
            'https://signup-campaign.com/sites/13439/index.php',
        ],
        Project5:[
            'Lindex Kampanje',
            lindex,
            'Marketsføring / Nyhetsbrev kampanje for Lindex',
            'https://signup-campaign.com/sites/13448/index.php?thelanguage=no'
        ],
        Project6:[
            'Vinoteket Kampanje',
            vinoteket,
            'Marketsføring / Nyhetsbrev kampanje for Vinoteket.se',
            'https://signup-campaign.com/sites/13462/'
        ],
        Project7:[
            'UNO clone',
            uno,
            'Gammelt og avglemt - UNO klone. uten styling men funksjonaliteten er tilstedet :)',
            'https://jonasthomassen.no/projects/uno/'
        ],
        Project8:[
            'Windows - JS Edition',
            win7,
            'Hobby prosjekt - WIP - Windows i nettleser. Drag & drop funksjonalitet. ',
            'https://jonasthomassen.no/projects/win7/'
        ],
        Project9:[
            'Trip.com Kampanje',
            tripdotcom,
            'Demo - tidligere markedsførings kampanje som er over og ikke lengre er tilgjengelig',
            'https://carreramediagroup.com/cases/trip-com/'
        ],       
        Project10:[
            'Bidroom Kampanje',
            bidroom,
            'Demo - tidligere markedsførings kampanje som er over og ikke lengre er tilgjengelig',
            'https://carreramediagroup.com/cases/bidroom/'
        ], 
        Project11:[
            'Hearthstone JS clone',
            stonehearth,
            'Gammelt og avglemt hobby prosjekt - Hearthstone klone. vil bli startet opp igjen på et senere tidspunkt :)',
            'https://jonasthomassen.no/projects/hearthstone/'
        ],
    }
    return language;
}

export default Norwegian;