import './error.css';

const ErrorPage = ( props )=>{
    return(
        <section className="Error">
            <h1>Sorry, something went wrong...</h1>
            <p>We are unable to display the page you are looking for</p>
            <button id="NO" onClick={ props.change }>Norwegian</button>
            <button id="EN" onClick={ props.change }>English</button>
        </section>
    )
}

export default ErrorPage;