import KnowledgeRow from '../../Components/knowledgeRow/knowledgeRow';
import './knowledge.css';
import { 
    html_icon, css_icon, js_icon, angular_icon,
    react_icon, vue_icon, sass_icon, ts_icon, 
    git_icon, sql_icon, node_icon, jquery_icon
} from '../../Resources/imageIndex';

const Knowledge = (props)=>{
    return(
        <div className="Page" id="Knowledge_Page">
            <h1>{ props.lang.knowledge_title }</h1>
            <KnowledgeRow img1={ html_icon } img2={ css_icon } img3={ js_icon }/>
            <p>{ props.lang.knowledge_text1 }</p>
            <KnowledgeRow img1={ react_icon } img2={ angular_icon } img3={ vue_icon }/>
            <KnowledgeRow img1={ sass_icon } img2={ ts_icon } img3={git_icon}/>
            <p>{ props.lang.knowledge_text2 }</p>
            <KnowledgeRow img1={ sql_icon } img2={ node_icon } img3={ jquery_icon }/>
        </div>
    )
}

export default Knowledge;