import './projectView.css';

const ProjectView = ( props )=>{
    return(
        <div className="projectView">
            <button onClick={ props.closeWindow }>X</button>
            <h2>{ props.title }</h2>
            <img src={ props.img } alt={ props.title }/>
            <div>
                <p>{ props.text }</p>
                <a href={ props.link } target='_blank' rel='noreferrer'>{ props.linkText }</a>
            </div>
        </div>
    )
}

export default ProjectView;