import { useState } from 'react';
import NavItem from '../navItem/navItem';
import './sidebar.css';

import arrow from '../../Resources/arrow_black.png';
import { about_black_icon, about_white_icon, knowledge_black_icon, 
        knowledge_white_icon, projects_black_icon, projects_white_icon,
        settings_black_icon, settings_white_icon
} from '../../Resources/imageIndex';

const Sidebar = (props)=>{

    const [ menuState, setMenuState ] = useState(!false);

    const toggleMenu = ()=>{
        setMenuState( !menuState )
        const nav = document.querySelector('#Sidebar');
        const title = document.querySelector('#Sidebar_top_title');
        const menuBtn = document.querySelector('#Sidebar_top_menuBtn');
        const navText = document.querySelectorAll('.NavItem_text');
        menuState ? nav.style.width = '175px': nav.style.width = '75px';
        menuState ? title.style.fontSize = '1rem' : title.style.fontSize = '0px';
        menuState ? menuBtn.style.transform = 'rotate(270deg)' : menuBtn.style.transform = 'rotate(90deg)';
        navText.forEach(element => {
            menuState ? element.style.fontSize = '.9rem' : element.style.fontSize = '0px';
        });
    }

    const changeIcons = ()=>{
        if( props.color === 'dark' ){
            return(
                <div className="Sidebar_middle">
                    <NavItem id="Home" class={ 'NavItem active' } img={ about_white_icon } handleClick={ props.onNavClick } text={ props.lang.sidebar_home }/>
                    <NavItem id="Knowledge" class={ 'NavItem' } img={ knowledge_white_icon } handleClick={ props.onNavClick } text={ props.lang.sidebar_knowledge }/>
                    <NavItem id="Projects" class={ 'NavItem' } img={ projects_white_icon } handleClick={ props.onNavClick } text={ props.lang.sidebar_projects }/>
                    <NavItem id="Settings" class={ 'NavItem' } img={ settings_white_icon } handleClick={ props.onNavClick } text={ props.lang.sidebar_settings } />
                </div>
            )
        } else{
            return(
                <div className="Sidebar_middle">
                    <NavItem id="Home" class={ 'NavItem active' } img={ about_black_icon } handleClick={ props.onNavClick } text={ props.lang.sidebar_home }/>
                    <NavItem id="Knowledge" class={ 'NavItem' } img={ knowledge_black_icon } handleClick={ props.onNavClick } text={ props.lang.sidebar_knowledge }/>
                    <NavItem id="Projects" class={ 'NavItem' } img={ projects_black_icon } handleClick={ props.onNavClick } text={ props.lang.sidebar_projects }/>
                    <NavItem id="Settings" class={ 'NavItem' } img={ settings_black_icon } handleClick={ props.onNavClick } text={ props.lang.sidebar_settings } />
                </div>
            )
        }
    }

    return(
        <section className="Sidebar" id="Sidebar">
            <div className="Sidebar_top">
                <p>Jonas</p>
                <h2 id="Sidebar_top_title">Thomassen</h2>
                <button onClick={ toggleMenu }>
                    <img id="Sidebar_top_menuBtn" src={ arrow } alt="Menu"/>
                </button>
            </div>
            { changeIcons() }
            <div className="Sidebar_bottom">
                <p>2022</p>
            </div>
        </section>
    )
}

export default Sidebar;