import { gameofboards, lindex, musicplayer, 
         qatarmarketing, spacefeed, uno, vinoteket, 
         win7, tripdotcom, bidroom, stonehearth } 
from '../Resources/imageIndex';

const English = ()=>{
    const language = {
        // Sidebar
        sidebar_home: 'Home',
        sidebar_knowledge: 'Knowledge',
        sidebar_projects: 'Projects',
        sidebar_settings: 'Settings',
        // Home
        home_title: 'Welcome!',
        home_text: "My name is Jonas,",
        home_text2: 'I am a technology enthusiast and front-end developer based in Oslo, Norway.',
        home_text3: 'Computer nerd with high interest in most things regarding tech, Videogame Extraordinaire',
        home_text4: 'and poweruser of both Windows and Linux',
        // Knowledge
        knowledge_title: 'Knowledge',
        knowledge_text1: 'Experience with:',
        knowledge_text2: 'Limited experience with:',
        // Projects
        projects_title: 'Projects',
        // Settings
        settings_title: 'Settings',
        lightBtn: 'Light mode',
        darkBtn: 'Dark mode',
        // Project elements
        Project_link: 'Open project',
        Project1: [
            'Game of Boards',
            gameofboards,
            'School project - create a board game in the browser based on the popular series Game of Thrones. Bug - refresh page when selecting character for first time',
            'https://jonasthomassen.no/projects/gameofboards/'
        ],
        Project2: [
            'SpaceFeed',
            spacefeed,
            'School project - create a website for fictional company SpaceFeed. for space enthusiasts to stay up to date on latest news. Old data from API',
            'https://jonasthomassen.no/projects/spacefeed/'
        ],
        Project3: [
            'Music player',
            musicplayer,
            'Hobby - A simple music player created for fun. currently 4 songs playing from an array',
            'https://jonasthomassen.no/projects/musicplayer/'
        ],
        Project4: [
            'Qatar Airways Campaign',
            qatarmarketing,
            'Marketing / Newsletter campaign for Qatar Airways',
            'https://signup-campaign.com/sites/13439/index.php',
        ],
        Project5:[
            'Lindex Campaign',
            lindex,
            'Marketing  / Newsletter campaign for Lindex',
            'https://signup-campaign.com/sites/13448/index.php?thelanguage=no'
        ],
        Project6:[
            'Vinoteket Campaign',
            vinoteket,
            'Marketing  / Newsletter campaign for Vinoteket.se',
            'https://signup-campaign.com/sites/13462/'
        ],
        Project7:[
            'UNO clone',
            uno,
            'Old and Forgotten - old UNO clone. not styled but the functionality is pretty much there :)',
            'https://jonasthomassen.no/projects/uno/'
        ],
        Project8:[
            'Windows - JS Edition',
            win7,
            'Old hobby project - WIP - Windows in the browser. drag and drop functionality',
            'https://jonasthomassen.no/projects/win7/'
        ],
        Project9:[
            'Trip.com Campaign',
            tripdotcom,
            'Demo - link to preview. campaign is over and no longer available',
            'https://carreramediagroup.com/cases/trip-com/'
        ],
        Project10:[
            'Bidroom Campaign',
            bidroom,
            'Demo - link to preview. campaign is over and no longer available',
            'https://carreramediagroup.com/cases/bidroom/'
        ],
        Project11:[
            'Hearthstone JS clone',
            stonehearth,
            'Old and forgotten hobby prosjekt - WIP - Hearthstone clone. Will pick this up when i get the time :)',
            'https://jonasthomassen.no/projects/hearthstone/'
        ],
    }
    return language;
}

export default English;